import React, { useEffect, useState } from "react"
import { addPropertyControls, ControlType } from "framer"

export default function ProgressAnimation(props) {
    const { backgroundColor, progressColor, timeAnimation } = props
    const [width, setWidth] = useState(0)

    useEffect(
        function () {
            const interval = setInterval(function () {
                setWidth((prevWidth) => (prevWidth >= 100 ? 0 : prevWidth + 1))
            }, timeAnimation)

            return function cleanup() {
                clearInterval(interval)
            }
        },
        [timeAnimation]
    )

    const styles = {
        container: {
            width: "100%",
            height: "100%",
            position: "relative",
            overflow: "hidden",
        },
        background: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: backgroundColor,
        },
        progress: {
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: `${width}%`,
            background: progressColor,
            transition: `width ${timeAnimation}ms linear`,
        },
    }

    return (
        <div style={styles.container}>
            <div style={styles.background}></div>
            <div style={styles.progress}></div>
        </div>
    )
}

addPropertyControls(ProgressAnimation, {
    backgroundColor: {
        title: "Background Color",
        type: ControlType.Color,
        defaultValue: "rgba(255, 255, 255, 0.1)",
    },
    progressColor: {
        title: "Progress Color",
        type: ControlType.Color,
        defaultValue: "#5FB4F2",
    },
    timeAnimation: {
        title: "Animation Time (ms)",
        type: ControlType.Number,
        defaultValue: 150,
        min: 10,
        max: 1000,
        step: 10,
    },
})
